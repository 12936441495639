import { getToken } from "../Services/token";

const get_profile = () => {
  const host = window.location.host;
  if (host === "ielts-cms.web.app") return "production";
  if (host === "cms-ielts-cambri.web.app") return "staging";
  if (host === "localhost:3000" || host === "localhost:3001") return "dev";
  return "unknown";
};

let be_url = "https://prod.api.cms.ielts.cambri.ai";
const profile = get_profile();
switch (profile) {
  case "dev":
    be_url = "https://prod.api.cms.ielts.cambri.ai";
    break;

  case "staging":
    be_url = "https://prod.api.cms.ielts.cambri.ai";
    break;

  case "production":
    be_url = "https://api.cms.ielts.cambri.ai";
    break;

  default:
    console.warn(`Unknown profile: ${profile}`);
    break;
}

export const serviceUrl = be_url;

export const genericHeaders = () => {
  if (getToken("token") != null) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken("token"),
    };
  }
  return {
    "Content-Type": "application/json",
  };
}; 