import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomButton from "../Common/CustomButton";
import axios from "axios";
import listeningStore from "../../Services/Stores/listeningStore";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../Common/CustomTextField";
import { FaPlus, FaTrash, FaArrowAltCircleUp } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { observer } from "mobx-react-lite";
import { genericHeaders, serviceUrl } from "../../utils/apiConfig";
import { IoIosArrowForward, IoMdArrowBack } from "react-icons/io";
import CustomToolTip from "../Common/CustomToolTip";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import QuestionTypeModal from "../Reading/QuestionTypeModal";
import OptionComponent from "../Reading/OptionComponent";
import "./fileInput.css";
import { GetFile, upload_file_to_s3 } from "../Common/FileUpload";
import ListeningQuestionTable from "../DynamicTableListening/Index";
import PwdQuestionTable from "../PMD";
import FileUpload from "../ImageUploader";
const Listening = observer(() => {
  const inputRef = useRef();
  const audioRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionType, setQuestionType] = useState("");
  const [isListeningAudioUpdated, setIsListeningAudioUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [audioUrl,setAudioUrl] = useState('');

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const {
    formData,
    sectionId,
    selectedSectionId,
    editMode,
    questionsCategory,
    selectedSectionName,
  } = listeningStore;

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const removeFile = () => {
    listeningStore.setFormData({ ...formData, audioFiles: [] });
  };

  const fetchQuestionCategories = async () => {
    try {
      const url = `${serviceUrl}/api/listening/questioncategory/getall`;
      const response = await axios.get(url, { headers: genericHeaders() });
      listeningStore.setQuestionsCategory(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getQuestionById = async () => {
    setLoading(true);
    try {
      const url = `${serviceUrl}/api/listeningquestions/getbyid?listeningAudioId=${id}`;
      const response = await axios.get(url, { headers: genericHeaders() });
      if (response.status === 200) {
        setLoading(false);
        const res = response.data;
        const questionTypesArray = questionsCategory;
        const questionTypeMapping = questionTypesArray.reduce((acc, type) => {
          acc[type._id] = type.name;
          return acc;
        }, {});
        const updatedQuestions = res.questions.map((question) => {
          const updatedQuestion = {
            ...question,
          };
          updatedQuestion.questionType =
            questionTypeMapping[question.questionType];
          return updatedQuestion;
        });
        const updatedResponse = { ...res, questions: updatedQuestions };
        listeningStore.setFormData(updatedResponse);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // const deleteQuestion = async (qId, questionId) => {
  //   if (editMode && questionId) {
  //     try {
  //       const url = `${serviceUrl}/api/listeningquestions/deletequestion?questionId=${questionId}&listeningAudioId=${formData._id}`;
  //       const response = await axios.delete(url, { headers: genericHeaders() });
  //       if (response.status === 204) {
  //         toast.success("Deleted Successfully");
  //         getQuestionById();
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   } else {
  //     let updatedQuestions = [...formData.questions];
  //     updatedQuestions.splice(qId, 1);
  //     listeningStore.setFormData({ ...formData, questions: updatedQuestions });
  //     toast.success("Deleted Successfully");
  //   }
  // };

  const deleteQuestion = async (qId, questionId) => {
    if (editMode && questionId) {
      try {
        const url = `${serviceUrl}/api/listeningquestions/delete?listeningQuestionId=${questionId}`;
        const response = await axios.delete(url, { headers: genericHeaders() });
        if (response.status === 200) {
          toast.success("Deleted Successfully");
          getQuestionById();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      let updatedQuestions = [...formData.questions];
      updatedQuestions.splice(qId, 1);
      listeningStore.setFormData({ ...formData, questions: updatedQuestions });
      toast.success("Deleted Successfully");
    }
  };

  const deleteOption = async (qIndex, qId, optionIndex) => {
    let updatedQuestions = [...formData.questions];

    updatedQuestions[qIndex].answers.splice(optionIndex, 1);
    listeningStore.setFormData({ ...formData, questions: updatedQuestions });
    toast.success("Option Deleted Successfully");
  };

  useEffect(() => {
    if (id && questionsCategory && questionsCategory.length > 0) {
      listeningStore.setEditMode(true);
      getQuestionById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, questionsCategory]);

  useEffect(() => {
    fetchQuestionCategories();
    return () => {
      listeningStore.setEditMode(false);
      setQuestionType("");
      setIsListeningAudioUpdated(false);
      listeningStore.setFormData({
        title: "",
        audioQuestionCountInfo: {},
        audioPartCount: null,
        audioFiles: [],
        questions: [],
      });
    };
  }, []);

  const questionTypeMapping = useMemo(() => {
    const mapping = {};
    if (questionsCategory && questionsCategory.length > 0) {
      questionsCategory.forEach((questionType) => {
        mapping[questionType.name] = questionType._id;
      });
    }
    return mapping;
  }, [questionsCategory]);

  const mapFormDataForAPI = (data) => {
    const mappedData = { ...data };
    mappedData["sectionId"] = selectedSectionId;

    mappedData.questions.forEach((question) => {
      question.questionType = questionTypeMapping[question.questionType];
    });

    mappedData.questions.forEach((question) => {
      const countAnswerPlaceholders = (text) => {
        const regex = /{[^}]*}/g;
        const matches = text.match(regex);
        return matches ? matches.length : 0;
      };

      const questionCount = countAnswerPlaceholders(question.question);
      // question.questionsCount = questionCount > 0 ? questionCount : 1;
    });

    return mappedData;
  };

  const submitandUpdateQuestion = async () => {
    setLoading(true);
    if (sectionId !== null) {
      try {
        let url = `${serviceUrl}/api/`;
        if (editMode) {
          url =
            url +
            `listeningquestions/update?listeningAudioId=${id}&isListeningAudioUpdated=${isListeningAudioUpdated}`;
        } else {
          url = url + "listeningquestions/create";
        }
        const updatedFormData = {
          ...formData,
          questions: formData.questions.map((question) => ({
            ...question,
            // questionTable: {
            //   ...question.questionTable,
            //   tbody: (question.questionTable?.tbody || []).map((row) => {
            //     const updatedRow = {};
            //     for (const key in row) {
            //       updatedRow[key === "" ? "planQuestion" : key] = row[key];
            //     }
            //     return updatedRow;
            //   }),
            // },
          })),
        };

        listeningStore.setFormData(updatedFormData);

        let payload = mapFormDataForAPI(updatedFormData);
        if (!editMode) {
          delete payload?._id;
        }
        const response = await axios[editMode ? "put" : "post"](url, payload, {
          headers: genericHeaders(),
        });
        if (response.status === 201 || response.status === 200) {
          setLoading(false);
          navigate("/listening");

          toast.success(
            editMode ? "Updated Sucessfully" : "Submitted Successfully"
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const createNewQuestion = (questionType) => {
    const newQuestion = {
      get_id: "",
      paragraphId: "",
      question: "",
      explanation: "",
      questionTable: {},
      answers: [],
      questionType: questionType,
    };
    return newQuestion;
  };

  const handleQuestionTypeChange = (e) => {
    if (questionType) {
      const updatedQuestions = [...formData.questions];
      const newQuestion = createNewQuestion(questionType);
      updatedQuestions.push(newQuestion);
      listeningStore.setFormData({
        ...formData,
        questions: updatedQuestions,
      });
      closeModal();
    }
  };

  const handleAddOption = (questionIndex) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[questionIndex].answers.push({
      isActive: true,
      answerOption: "",
      isCorrectAnswer: false,
      answerFieldType: "string",
      correctAnswer: "",
    });
    listeningStore.setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddPlaceholder = (questionIndex, optionIndex) => {
    const existingAnswerOption = formData.questions[questionIndex].question;
    const inputElement = document.getElementById(
      `optionInput-${questionIndex}-${optionIndex}`
    );
    const cursorPosition = inputElement.selectionStart;

    const newAnswerOption =
      existingAnswerOption.slice(0, cursorPosition) +
      "{answer}" +
      existingAnswerOption.slice(cursorPosition);
    formData.questions[questionIndex].question = newAnswerOption;
  };
  const handleFormDataChange = (
    e,
    questionIndex,
    optionIndex,
    questionType,
    field
  ) => {
    const updatedQuestions = [...formData.questions];
    if (
      field === "question" ||
      field === "explanation" ||
      field === "questionIndex" ||
      field === "questionsCount"
    ) {
      updatedQuestions[questionIndex][field] = e.target.value;
    } else if (field === "answerOption") {
      updatedQuestions[questionIndex].answers[optionIndex][field] =
        e.target.value;
    }

    if (
      questionType !== "Multiple Choice (more than one answer)" &&
      field === "isCorrectAnswer"
    ) {
      updatedQuestions[questionIndex].answers.forEach((option, idx) => {
        if (idx === optionIndex) {
          option.isCorrectAnswer = !option.isCorrectAnswer;
        } else {
          option.isCorrectAnswer = false;
        }
      });
    } else if (
      questionType === "Multiple Choice (more than one answer)" &&
      field === "isCorrectAnswer"
    ) {
      updatedQuestions[questionIndex].answers[optionIndex].isCorrectAnswer =
        !updatedQuestions[questionIndex].answers[optionIndex].isCorrectAnswer;
    }
    listeningStore.setFormData({ ...formData, questions: updatedQuestions });
  };

  const handle_file_upload = async (new_file) => {
    let public_url;
    try {
      setUploading(true);
      const s3Data = await upload_file_to_s3(new_file[0]);
      public_url = s3Data?.data;

      if (Object.keys(public_url).length !== 0) {
        setUploading(false);
      }

      const transformedFiles = Array.from(new_file).map((file) => ({
        fileName: file.name,
        name: file.name,
        resourceName: '',
        uploadDate: new Date().toISOString(),
        comment: "",
        extension: file.name.split(".").pop(),
        s3Document:public_url
      }));

      setIsListeningAudioUpdated(true);
      listeningStore.setFormData({
        ...formData,
        audioFiles: transformedFiles,
      });
    } catch (err) {
      console.error(err);
      setUploading(false);
    }
  };

  const deleteListeningAudio = async () => {
    if (editMode && formData._id) {
      try {
        const url = `${serviceUrl}/api/listeningaudio/delete?listeningAudioId=${formData._id}`;
        const response = await axios.delete(url, { headers: genericHeaders() });
        if (response.status === 200) {
          toast.success("Deleted Successfully");
          getQuestionById();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else {
      listeningStore.setFormData({ ...formData, audioFiles: [] });
    }
  };

  const handleQuestionChange = (e, index) => {
    const updatedQuestions = formData.questions.map((question, i) => ({
      ...question,
      heading: i === index ? e.target.value : question.heading,
    }));

    listeningStore.setFormData({
      ...formData,
      questions: updatedQuestions,
    });
  };

  const handleChangeAudioPartsCount = (e) => {
    if (/^\d*$/.test(e.target.value)) {
      setIsListeningAudioUpdated(true);
      listeningStore.setFormData({
        ...formData,
        audioPartCount: e.target.value,
      });
    }
    if (e.target.value === 0 || !e.target.value) {
      listeningStore.setFormData({ ...formData, audioPartCount: null });
    }
  };
  useEffect(() => {
    const fetchFileUrl = async () => {
      if(listeningStore?.formData?.audioFiles[0]?.resourceName?.trim() !== ""){
        setAudioUrl(listeningStore?.formData?.audioFiles[0]?.resourceName)
      }else{
        let fileName = listeningStore?.formData?.audioFiles[0]?.s3Document?.fileName ?? "";
        if (fileName !== "") {
          let file_Url = await GetFile(fileName);
          file_Url ? setAudioUrl(file_Url) : setAudioUrl("")
        }
      }
    };

    fetchFileUrl();
  }, [listeningStore?.formData?.audioFiles]);
  useEffect(() => {
    if (audioUrl && audioRef.current) {
      audioRef.current.load();
    }
  }, [audioUrl]);
  return (
    <div className="flex flex-col h-full mx-auto w-full mt-10 p-8">
      {loading ? (
        <div className="flex justify-center items-center min-h-screen">
          <RotatingLines
            visible={true}
            strokeColor="red"
            height="96"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="bg-gray-100">
          <div>
            <button
              className="hover:bg-slate-500 hover:text-white rounded-md flex items-center bg-gray-300 py-2 px-6 mb-4"
              onClick={() => navigate("/listening")}
            >
              <IoMdArrowBack /> <span className="ml-1">Back</span>
            </button>
          </div>
          <p className="flex items-center mb-4 font-medium text-lg">
            {selectedSectionName} <IoIosArrowForward />{" "}
            {editMode ? "Edit Audio" : "Upload Audio"}
          </p>
          <CustomTextField
            required={true}
            name="title"
            handleChange={(e) => {
              setIsListeningAudioUpdated(true);
              listeningStore.setFormData({
                ...formData,
                title: e.target.value,
              });
            }}
            label={"Title"}
            value={formData.title}
          />
          <div className="mt-2">
            <input
              className="hidden"
              ref={inputRef}
              type="file"
              accept="audio/*"
              onChange={(e) => {
                handle_file_upload(e.target.files);
              }}
            />
            <button className="file-btn" onClick={onChooseFile}>
              <span class="material-symbols-rounded">
                <FaArrowAltCircleUp />
              </span>{" "}
              Upload Audio
            </button>

            {formData.audioFiles &&
            formData.audioFiles.length > 0 &&
            formData.audioFiles[0] ? (
              <div className="selected-file mb-2">
                <div className="flex items-center">
                  <p>{formData.audioFiles[0].name}</p>
                  {/* <button onClick={deleteListeningAudio}>
                    <span class="material-symbols-rounded ml-2">
                      <FaTrash />
                    </span>
                  </button> */}
                </div>
                <audio
                  ref={audioRef}
                  controls
                  className="bg-[#f3ecff] px-4 rounded-[20px]"
                >
                  <source
                    src={audioUrl}
                    type="audio/mp3"
                  />
                  Your browser does not support the audio element.
                </audio>
              </div>
            ) : (
              isUploading && (
                <div className="selected-file py-2 px-4"> Uploading...... </div>
              )
            )}
          </div>
          {/* {convertingAudio && <p>Converting audio...</p>}
          <CustomTextField
          readOnly
            required={true}
            name="transCription"
            label={"Transcription"}
            value={formData.transcription}
          /> */}
          <CustomTextField
            handleChange={handleChangeAudioPartsCount}
            label={"Audio parts Count"}
            name={"audioParts"}
            placeholder={"Enter Audio Parts Count"}
            value={formData?.audioPartCount}
            required
          />
          <div className="flex items-center gap-2 mt-3 flex-wrap w-full">
            {formData?.audioPartCount > 1 &&
              Array.from({ length: formData?.audioPartCount }, (_, index) => (
                <div key={index} className="w-1/3">
                  <label>
                    Audio Part {index + 1}:
                    <input
                      pattern="\d*"
                      inputMode="numeric"
                      value={formData?.audioQuestionCountInfo?.[index] || ""}
                      onChange={(e) => {
                        if (/^\d*$/.test(e.target.value)) {
                          setIsListeningAudioUpdated(true);
                          listeningStore.setFormData({
                            ...formData,
                            audioQuestionCountInfo: {
                              ...formData.audioQuestionCountInfo,
                              [index]: +e.target.value,
                            },
                          });
                        }
                      }}
                      className="ml-2 border bg-white border-gray-200 rounded-lg text-sm py-2 px-4 w-1/3"
                      type="text"
                    />
                  </label>
                </div>
              ))}
          </div>
          {(questionType || editMode) && (
            <div>
              {formData &&
                formData.questions &&
                formData.questions.length > 0 &&
                formData.questions.map((question, questionIndex) => {
                  const { questionType } = question;
                  let hasPlaceholder = "";
                  if (question.answers && question.answers.length > 0) {
                    hasPlaceholder = /{(\w+)}/.test(question.question);
                  }
                  return (
                    <div
                      key={questionIndex}
                      className="flex flex-col w-full border border-gray-300 p-4 my-4 rounded-md"
                    >
                      <div className="my-2">
                        <div className="flex items-center w-full mb-2">
                          <p className="flex-1 text-gray-900 font-semibold">
                            {questionType}
                          </p>
                          <div className="flex justify-end">
                            <FaTrash
                              data-tooltip-id="delete_question"
                              className="text-[red] cursor-pointer flex justify-end"
                              onClick={() =>
                                deleteQuestion(questionIndex, question._id)
                              }
                            />
                          </div>
                        </div>
                        {questionType === "Note Completion" && (
                          <>
                            <div className="font-bold text-xl">Heading</div>
                            <>
                              <CustomTextField
                                key={questionIndex}
                                //id={`optionInput-${questionIndex}-0`}
                                handleChange={(e) =>
                                  handleQuestionChange(e, questionIndex)
                                }
                                value={question.heading}
                                placeholder={"Enter"}
                              />
                            </>
                          </>
                        )}
                        {questionType === "Flow-Chart Completion" && (
                          <div className="-mt-16 mb-6">
                            <FileUpload
                              qIndex={questionIndex}
                              url={question?.imageUrl}
                            />
                          </div>
                        )}
                        <label className="mb-1 text-[#4E5664] flex items-center justify-between w-full">
                          Question {questionIndex + 1}
                        </label>
                        <CustomToolTip
                          place={"bottom"}
                          content={"Delete Question"}
                          id={"delete_question"}
                        />
                        <div className="flex items-center">
                          <CustomTextField
                            id={`optionInput-${questionIndex}-${0}`}
                            handleChange={(e) => {
                              const inputValue = e.target.value;
                              handleFormDataChange(
                                e,
                                questionIndex,
                                "",
                                questionType,
                                "question"
                              );
                            }}
                            value={question.question}
                            placeholder={"Enter"}
                          />
                          {(questionType === "Note Completion" ||
                            questionType === "Sentence Completion" ||
                            questionType === "Flow-Chart Completion" ||
                            questionType === "Short Answer") && (
                            <div className="ml-2 justify-end">
                              {hasPlaceholder &&
                              questionType !== "Sentence Completion" ? (
                                <div data-tooltip-id="my-tooltip-1">
                                  {/* <button
                                className="inline-block ml-2"
                                onClick={() =>
                                  handleDeletePlaceholder(questionIndex, questionIndex)
                                }
                              >
                                <MdOutlineDelete className="text-[red] text-lg" />
                              </button>
                              <Tooltip
                                style={{
                                  color: "black",
                                  background: "#FFEAEA",
                                  borderRadius: "5px",
                                }}
                                id="my-tooltip-2"
                                place="bottom"
                                content="Add Answer"
                              /> */}
                                </div>
                              ) : (
                                <div data-tooltip-id="my-tooltip-2">
                                  <button
                                    className="inline-block ml-2"
                                    onClick={() =>
                                      handleAddPlaceholder(questionIndex, 0)
                                    }
                                  >
                                      <FaPlus />
                                  </button>
                                  <Tooltip
                                    style={{
                                      color: "black",
                                      background: "#FFEAEA",
                                      borderRadius: "5px",
                                    }}
                                    id="my-tooltip-2"
                                    place="bottom"
                                    content="Add Answer"
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                      {(questionType === "Sentence Completion" ||
                        questionType === "Note Completion" ||
                        questionType === "Flow-Chart Completion" ||
                        questionType === "Flow-Chart Completion") &&
                        question.answers.map((option, optionIndex) => (
                          <OptionComponent
                            questionIndex={questionIndex}
                            optionIndex={optionIndex}
                            questionType={questionType}
                            option={option}
                            handleFormDataChange={handleFormDataChange}
                            alphabeticalCount={alphabet[optionIndex]}
                            handleDeleteOption={deleteOption}
                            qId={question._id}
                          />
                        ))}

                      {questionType === "Multiple choice" &&
                        question.answers.map((option, optionIndex) => {
                          const alphabeticalCount = alphabet[optionIndex];
                          return (
                            <div key={optionIndex}>
                              <label className="mr-4 mb-1 text-[#4E5664] flex items-center">
                                Option {alphabeticalCount}{" "}
                                <FaTrash
                                  onClick={() => {
                                    deleteOption(
                                      questionIndex,
                                      question._id,
                                      optionIndex
                                    );
                                  }}
                                  className="text-[red] ml-2 cursor-pointer"
                                />
                              </label>
                              <div className="flex items-center my-2 w-full">
                                <input
                                  className="flex-1 py-3 px-4 mr-10 bg-white border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                  type="text"
                                  placeholder="Option"
                                  value={option.answerOption}
                                  onChange={(e) =>
                                    handleFormDataChange(
                                      e,
                                      questionIndex,
                                      optionIndex,
                                      questionType,
                                      "answerOption"
                                    )
                                  }
                                />
                                <div className="flex items-center justify-end">
                                  <input
                                    className="mr-2 bg-white"
                                    type="radio"
                                    name={`option_${questionIndex}`}
                                    checked={option.isCorrectAnswer}
                                    onChange={(e) => {
                                      handleFormDataChange(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "Multiple Choice",
                                        "isCorrectAnswer"
                                      );
                                    }}
                                  />

                                  <label>Correct Answer</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {questionType === "Table Completion" && (
                        <ListeningQuestionTable
                          questionType={questionType}
                          questionIndex={questionIndex}
                        />
                      )}
                      {questionType === "plan/map/diagram labelling" && (
                        <div
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        //   justifyContent: "center",
                        //   alignItems: "center",
                        //   gap: "40px",
                        //   marginLeft:"-300px"
                        // }}
                        >
                          <div className="mt-[-2rem]">
                            <FileUpload
                              qIndex={questionIndex}
                              url={listeningStore.uploadURL}
                            />
                          </div>
                          <PwdQuestionTable
                            questionType={questionType}
                            questionIndex={questionIndex}
                          />
                        </div>
                      )}

                      {(questionType ===
                        "Multiple choice (More than one answer)" ||
                        questionType === "Short Answer") &&
                        question.answers.map((option, optionIndex) => {
                          const alphabeticalCount = alphabet[optionIndex];
                          return (
                            <div key={optionIndex}>
                              <div className="flex items-center my-2 w-full">
                                <label className="mr-2 text-[#4E5664] flex items-center">
                                  Option {alphabeticalCount}{" "}
                                  <FaTrash
                                    onClick={() => {
                                      deleteOption(
                                        questionIndex,
                                        question._id,
                                        optionIndex
                                      );
                                    }}
                                    className="text-[red] ml-2 cursor-pointer"
                                  />
                                </label>
                                <input
                                  className="flex-1 py-3 px-4 mr-10 border bg-white border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                  type={"text"}
                                  value={option.answerOption}
                                  onChange={(e) =>
                                    handleFormDataChange(
                                      e,
                                      questionIndex,
                                      optionIndex,
                                      questionType,
                                      "answerOption"
                                    )
                                  }
                                />
                                <div className="flex items-center justify-end">
                                  <input
                                    className="mr-2 bg-white"
                                    type="checkbox"
                                    checked={option.isCorrectAnswer}
                                    onChange={(e) => {
                                      handleFormDataChange(
                                        e,
                                        questionIndex,
                                        optionIndex,
                                        "Multiple Choice (more than one answer)",
                                        "isCorrectAnswer"
                                      );
                                    }}
                                  />

                                  <label>Correct Answer</label>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      {/* {questionType && ( */}
                      <div>
                        {questionType !== "Table Completion" && (
                          <button
                            className="border border-[#E31837] text-[#E31837] rounded-full my-4 px-4 py-1"
                            onClick={() => handleAddOption(questionIndex)}
                          >
                            Add Option
                          </button>
                        )}
                      </div>

                      {/* )} */}
                      <div className="my-2">
                        <label className="mr-2 mb-2 text-[#4E5664]">
                          Explanation
                        </label>
                        <CustomTextField
                          handleChange={(e) =>
                            handleFormDataChange(
                              e,
                              questionIndex,
                              "",
                              questionType,
                              "explanation"
                            )
                          }
                          value={question.explanation}
                          placeholder={"Enter"}
                        />
                        <CustomTextField
                          handleChange={(e) => {
                            handleFormDataChange(
                              e,
                              questionIndex,
                              "",
                              questionType,
                              "questionIndex"
                            );
                          }}
                          value={question.questionIndex}
                          placeholder={"Enter"}
                          label={"Question Index"}
                        />
                        <CustomTextField
                          handleChange={(e) => {
                            handleFormDataChange(
                              e,
                              questionIndex,
                              "",
                              questionType,
                              "questionsCount"
                            );
                          }}
                          value={question.questionsCount}
                          placeholder={"Enter"}
                          label={"Question Count"}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          <div>
            <button
              disabled={
                !formData.title ||
                formData.title === "" ||
                !formData.audioFiles ||
                formData.audioFiles.length === 0 ||
                formData.audioPartCount === null ||
                formData.audioPartCount < 0
              }
              className={`${
                !formData.title ||
                formData.title === "" ||
                !formData.audioFiles ||
                formData.audioFiles.length === 0 ||
                formData.audioPartCount === null ||
                formData.audioPartCount < 0
                  ? "text-gray-400 border-gray-400 "
                  : "text-[#E31837] border-[#E31837] "
              }border rounded-3xl px-4 py-1 my-4`}
              onClick={openModal}
            >
              Add Question
            </button>
          </div>

          {formData && formData.questions && formData.questions.length > 0 && (
            <div className="pb-4">
              <CustomButton
                disabled={loading}
                classes={"bg-[#E31837] text-white rounded-3xl px-4 py-1 my-4"}
                label={editMode ? "Update" : "Submit"}
                handleClick={submitandUpdateQuestion}
              />
            </div>
          )}
          <QuestionTypeModal
            isOpen={isModalOpen}
            onClose={closeModal}
            onQuestionTypeChange={handleQuestionTypeChange}
            setQuestionType={setQuestionType}
            data={listeningStore.questionsCategory}
          />
        </div>
      )}
    </div>
  );
});

export default Listening;
