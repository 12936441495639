import axios from "axios";


const headers1={
  "username":"ictsystems",
  "password":"123456789",
  "x-api-key":"987654321",
  "applicationurl":"localhost:3000"
}
export const upload_file_to_s3 = async(
  file
) => {
  try{
    const url="http://localhost:8080/api/s3/upload";
    
    const formData = new FormData();
    formData.append("file",file);
    const res = await axios.post(url, formData, {
      headers: {
        ...headers1,
        "Content-Type": "multipart/form-data",
        "Accept":"*/*"
      },
    });
    if(res.status==200){
      return res.data;
      //set the response object to any state
    }

  }catch(err){
    console.log(err);
    //if any error is handle here
  }
  //we have to sent the file to aws microservice 
  //get the details object of that image
  // return the api response if it gets success
  // return {
  //   "fileName":"",
  //   "folderPath":"",
  //   "timeStamp":"",
  //   "bucketName":"",
  //   "region":""
  // }
};

export const GetFile = async(file_name)=>{
  try {
      const url = 'http://localhost:8080/api/s3/generate/presigned/url';
      let payload = {
        'objectKey': file_name
      }
      const response = await axios.post(url,payload,{ headers: {
        'username' : 'ictsystems',
        'password' : '123456789',
        'x-api-key': '987654321',
        'applicationurl':'localhost:3000'
      } });
      if(response.status === 200){
        return response?.data?.data;
      }
      return ''; 
    } catch (error) {
      console.error("Error fetching data:", error);
      return '';
    }
}
